import "./Home.css"
import {useNavigate} from "react-router-dom"


const OptionBlock = () => {
    
}





export const Home = () => {    
    
    const navigate = useNavigate();
    return(
    <div className="outer">
        <div className="welcomeMsgBox">
            <div className="welcomeMsg">
                Welcome!
            </div>
            <h1 className="welcomeMsg2">Let's learn your students' names.</h1>
            
        </div>
        <div className="butRow">
            <div className="inBox" onClick={()=>{navigate("fcPage")}}>
                Flashcards
                <div className="inBox2">
                    Click to practice student names and faces
                </div>
            </div>
            <div className="inBox" onClick={()=>{navigate("dashboard")}}>
                Statistics
                <div className="inBox2">
                    Click to view your practicing stats
                </div>
            </div>
        </div>
        {/* <div className="butRow">
            <div className="inBox">
                <a>Calendar</a>
            </div>
            <div className="inBox">
                <a>Other Stuff</a>
            </div>
        </div> */}
    </div>
    );
}