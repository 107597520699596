import { useState } from "react"
import "./Dashboard.css"
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    Label
  } from "recharts";





const StatsAtAGlance = () => {
    
    const userData = [
        {
            date: "10/20", 
            acc: "14.7"
        },
        {
            date: "10/21", 
            acc: "33.33"
        },
        {
            date: "10/22", 
            acc: "60.3"
        },
        {
            date: "10/23", 
            acc: "69.42"
        },
        {
            date: "10/24", 
            acc: "91.3"
        }
        
    ]
    
    return(
        <div className="stats_ata">
            <div className="statsBlock">
                <h2>Accuracy Over Time</h2>
                <LineChart width={500} height={300} data={userData}>
                    <CartesianGrid strokeDasharray="3 3"/>
                    <XAxis dataKey="date" padding={{ left: 30, right: 30 } } tick={{fill:"black"}}>
                        <Label fill="black" value="Date" offset={-5} position="insideBottom" ></Label>
                    </XAxis>
                    <YAxis padding={{top:30, bottom:30}} tick={{fill:"black"}}>
                        <Label fill="black" value="Accuracy (%)" offset={10} position="insideLeft" angle={-90}/>
                    </YAxis>
                    <Tooltip />
                    <Legend />
                    <Line
                        type="monotone"
                        dataKey="acc"
                        stroke="#8884d8"
                        activeDot={{ r: 8 }}
                    />
                </LineChart>
            </div>
            
  
        </div>
    );
}

const GlobalStats = () => {

}


const Headers = () => {
    

    
    return(
        <div className="headers">
            <div>Overall Stats</div>
            <div>Per-Class Stats</div>
        </div>
    );
}


export const Dashboard = () => {
    const [activeStats, updateActiveStats] = useState(0);
    
    console.log("rendering dashboard")
    return(
        <div className="outer">
            <StatsAtAGlance></StatsAtAGlance>
        </div>
    );
}