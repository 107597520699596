import "./Home.css"
import "./FlashcardPage/FCPage.css"
import { useRef, useEffect, useState } from 'react'
import classnames from 'classnames'
import {useNavigate} from 'react-router-dom' 


interface FCInt {
    numStudents: number
    curStudentIdx: number 
    incStudent: () => void
    // rightStudent: () => void
    // wrongStudent: () => void
}


const FlashCard: React.FC<FCInt> = ({numStudents, curStudentIdx, incStudent}) => {

    const studentList = [
        {"name": "Deandre Aly", 
            "pic_url": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRQJntA5zQzP8497j2BfiKG9gQh2VwZQYUiFA&s"
        },
        {"name": "Cassie Jordan", 
            "pic_url": "https://aka1908.com/wp-content/uploads/2022/11/jyoung-300x300.jpg"
        },
        {"name": "Julie Yang", 
            "pic_url": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRxcN692vqHTptUX7JyWSTImDV1YJHSyJzZ2g&s"
        },
        {"name": "Barbara Smith", 
            "pic_url": "https://www.wbcsd.org/wp-content/uploads/2024/04/Ilham-Kadri-300x300.png"
        },
        {"name": "Taran Patel", 
            "pic_url": "https://cdn.learfield.com/wp-content/uploads/2022/04/Ben_800-300x300.png"
        },
        {"name": "Childish Gambino", 
            "pic_url": "https://cdn-p.smehost.net/sites/005297e5d91d4996984e966fac4389ea/wp-content/uploads/2020/01/Childish-Gambino-300x300.png"
        },
        {"name": "Whittney Arthur", 
            "pic_url": "https://www.hsfoundation.org/wp-content/uploads/Sophie-2-300x300.jpg"
        },
        {"name": "Jiwho Wang", 
            "pic_url": "https://history.columbia.edu/wp-content/uploads/sites/29/2016/09/Ngai-pic-12-300x300.png"
        },
        {"name": "Bob Burnett", 
            "pic_url": "https://retractionwatch.com/wp-content/uploads/2024/05/ivan-oransky-head-shot-Cropped-1-300x300.jpg"
        },
        {"name": "Anish Asnani", 
            "pic_url": "https://pbs.twimg.com/media/GaHHtIGWwAAVRd2.png"
        }
    ]

    const cardRef = useRef<HTMLDivElement>(null);  

    //some variables to make sliding card easy, nice
    const mvThresh = 10;
    const lerpFactor = 0.1;
    const maxYOff = 80;
    const accThresh = 250; //TODO: figure out a diff way of doing this
    

    

    const [cardWidth, setCardWidth] = useState(40);
    const [cardHeight, setCardHeight] = useState(20);


    
    //user interfacing variables
    const [isClicked, setClicked] = useState(false);
    const [isDragging, setDragging] = useState(false);


    const refClicked = useRef(isClicked)
    const refDragging = useRef(isDragging)
    //because closures suck
    useEffect(() => {
        refClicked.current = isClicked;
        refDragging.current = isDragging;
    }, [isClicked, isDragging])
    

    //start of dragging variables
    const [sx, setSx] = useState(0);
    const [sy, setSy] = useState(0);

    //because closures suck
    const refSX = useRef(sx);
    const refSY = useRef(sy);
    useEffect(() => {
        refSX.current = sx;
        refSY.current = sy;
    }, [sx, sy])

    //card status variables
    const [xoff, setXOff] = useState(0);
    const [yoff, setYOff] = useState(0);
    
    //because closures suck
    const refXOff = useRef(xoff);
    const refYOff = useRef(yoff);
    useEffect(() => {
        refXOff.current = xoff;
        refYOff.current = yoff;
    }, [xoff, yoff])

    const [flipped, setFlipped] = useState(false);
    const refFlipped = useRef(flipped);
    useEffect(() => {
        refFlipped.current = flipped;
    }, [flipped])

    const [correctSide, setCorrectSide] = useState(false);
    const refCorrectSide = useRef(correctSide);
    useEffect(() => {
        refCorrectSide.current = correctSide;
    }, [correctSide]);

    const regText = "Photo"
    const flipText = "Name"


    //bkg attributes
    const[sideIntensity, setSideIntensity] = useState(0);
    const bkgClasses = classnames({'correctbg':correctSide, "wrongbg": !correctSide});


    
    const lerp = (a: number, b: number, t: number) => {
        return a + (b - a) * t;
    }

    const handleClick = (e: React.MouseEvent<HTMLDivElement>): void => {
        setClicked(true);
        setDragging(false);
        setSx(e.screenX);
        setSy(e.screenY);
    }


    useEffect(() => {
        
        const intensity = Math.min((Math.abs(xoff) - 100)/200);

        if(xoff > 100)
        {
            setCorrectSide(true);
            setSideIntensity(intensity);
        }
        else if(xoff < -100)
        {
            setCorrectSide(false);
            setSideIntensity(intensity);
        }
        else {
            setSideIntensity(0);
        }


    }, [xoff, yoff])
    

    const handleMove = (e: MouseEvent): void => {
        const maxRot = 50;
        let dx = e.screenX - refSX.current;
        let dy = e.screenY - refSY.current;
        let distMoved = Math.sqrt(dx * dx + dy * dy)
        if(refDragging.current)
        {
            dy = dy > 0?Math.min(dy, maxYOff):Math.max(dy, -1 * maxYOff);
            const calcAngle = (maxRot / accThresh) * dx;
            const cappedAngle =  Math.min(maxRot, Math.max(calcAngle, -1 * maxRot))
            setXOff(dx);
            setYOff(dy);
            setAngle(cappedAngle)
        }
        else if(distMoved > mvThresh && refClicked.current)
            setDragging(true);
        else 
            setDragging(false);
        
        console.log("isclicked: " + refClicked.current);

    }

    

    const [throwGo, setThrowGo] = useState(false);
    const throwGoRef = useRef(throwGo);

    useEffect(() => {
        throwGoRef.current = throwGo;
    }, [throwGo]);

    //animation functions
    
    const [cardAngle, setAngle] = useState(0);

    //TODO: make this fps agnostic
    //TODO: make this have physics --> force carried over
    const animThrow = () => {
        const mvSpeed = 10;
        const rotSpeed = 4;
        const sign = refXOff.current / Math.abs(refXOff.current);
        setXOff((prev) => { return prev + mvSpeed * sign});
        
        console.log("throwing");
        if(throwGoRef.current)
        {
            setAngle((prev) => {return prev + sign * rotSpeed});
            requestAnimationFrame(animThrow);

        }
        else{
            setAngle(0);
        }
    }

    useEffect(() => 
    {
        if(throwGo)
            animThrow();

    }, [throwGo])



    const initCardTP = () => {
        //some logic to wait for the image to load probably
        setXOff(0);
        setYOff(0);
        setAngle(0);
        setCardHeight(0);
        setCardWidth(0);
    }

    useEffect(() => {
        const growLerp = 0.001;
        setCardHeight((prev) => {return lerp(prev, 20, growLerp)});
        setCardWidth(((prev) => {return lerp(prev, 40, growLerp)}));
    }, [cardHeight, cardWidth])

    const initCardThrow = () => {
        setThrowGo(true);
        animThrow();
        setTimeout(()=>{
            setThrowGo(false);
            incStudent();
            setFlipped(true);
            initCardTP();}, 500);
    }

    useEffect(() => {
        if(curStudentIdx >= numStudents && numStudents != 0)
        {
            console.log("go to end");
        }
    }, [curStudentIdx, numStudents])

    


    const returnToStart = () => 
    {
        setAngle(0);
        setXOff((prev) => {return lerp(prev, 0, lerpFactor)})
        setYOff((prev) => {return lerp(prev, 0, lerpFactor)})
        console.log("xoff: " + refXOff.current);
        console.log("yoff: " + refYOff.current);
        console.log(refClicked.current)
        if((refXOff.current != 0 || refYOff.current != 0) && !refClicked.current)
            requestAnimationFrame(returnToStart)
        
    }


    

    const handleUnclick = (e: MouseEvent): void => {
        setClicked(false);
    }

    useEffect(() => 
        {
            if(!isClicked){
                
                if(Math.abs(refXOff.current) > accThresh) //if the card is moved farther than the acceptance threshold we set up
                {
                    setDragging(false);
                    initCardThrow();

                }
                else if(refDragging.current)
                {
                    setDragging(false);
                    returnToStart();
                }
                else
                    setFlipped(!refFlipped.current);
    
            }
        }, [isClicked])
    
    // Handles flipping of the card
    // useEffect(() => 
    // {
    //     if(!cardRef.current)
    //         return;
    //     if(flipped)
    //         cardRef.current.innerText = flipText;
    //     else 
    //         cardRef.current.innerText = regText;

    // }, [flipped])

    useEffect(() => 
    {
        document.addEventListener("mousemove", handleMove);
        return () => {
            document.removeEventListener("mousemove", handleMove);
        };
    }, [])

    useEffect(() => 
    {
        document.addEventListener("mouseup", handleUnclick);
        return () => {
            document.removeEventListener("mouseup", handleUnclick);
        }
    })

    
    return(
        <>
        <div className="card" ref ={cardRef} id = "card" onMouseDown={handleClick} 
                    style = {{ transform:`rotate(${cardAngle}deg)`, height: `${cardHeight}em`, width: `${cardWidth}em`, top:yoff, left:xoff}}>
               <div className="noselect" style = {{fontSize:`${cardHeight * 2}px`, display: (!flipped)?"flex":"none"}}>
                        {(studentList[curStudentIdx])?studentList[curStudentIdx].name:"loading name"} 
                    </div>
                    <img className="noselect" style = {{borderRadius:"50%", maxHeight: "80%", maxWidth: "50%", display: (flipped)?"flex":"none"}}
                        src = {(studentList[curStudentIdx])?studentList[curStudentIdx].pic_url:"loading name"}
                    ></img>
                    
            </div>
            <div className={bkgClasses} style={{opacity: sideIntensity}}>
                
            </div>
            <div className="bruh" style = {{position: "absolute", bottom:0, textAlign: "center", color: "white", display: (flipped)?"flex":"none"}}>Remember their face? Click the card to flip it and find out if you do!</div>
            <div className="bruh" style = {{position: "absolute", bottom: 0,  textAlign:"center", color: "white", display: (flipped)?"none":"flex"}}>Did you get it right? Click and drag the card to the right if you got it right or the left if you got it wrong.</div>
        </>  
        
            
            );
}


export const FCPage = () => {

    const navigate = useNavigate()

    const studentList = [
        {"name": "Deandre Aly", 
            "pic_url": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRQJntA5zQzP8497j2BfiKG9gQh2VwZQYUiFA&s"
        },
        {"name": "Cassie Jordan", 
            "pic_url": "https://aka1908.com/wp-content/uploads/2022/11/jyoung-300x300.jpg"
        },
        {"name": "Julie Yang", 
            "pic_url": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRxcN692vqHTptUX7JyWSTImDV1YJHSyJzZ2g&s"
        },
        {"name": "Barbara Smith", 
            "pic_url": "https://www.wbcsd.org/wp-content/uploads/2024/04/Ilham-Kadri-300x300.png"
        },
        {"name": "Taran Patel", 
            "pic_url": "https://cdn.learfield.com/wp-content/uploads/2022/04/Ben_800-300x300.png"
        },
        {"name": "Childish Gambino", 
            "pic_url": "https://cdn-p.smehost.net/sites/005297e5d91d4996984e966fac4389ea/wp-content/uploads/2020/01/Childish-Gambino-300x300.png"
        },
        {"name": "Whittney Arthur", 
            "pic_url": "https://www.hsfoundation.org/wp-content/uploads/Sophie-2-300x300.jpg"
        },
        {"name": "Jiwho Wang", 
            "pic_url": "https://history.columbia.edu/wp-content/uploads/sites/29/2016/09/Ngai-pic-12-300x300.png"
        },
        {"name": "Bob Burnett", 
            "pic_url": "https://retractionwatch.com/wp-content/uploads/2024/05/ivan-oransky-head-shot-Cropped-1-300x300.jpg"
        },
        {"name": "Anish Asnani", 
            "pic_url": "https://pbs.twimg.com/media/GaHHtIGWwAAVRd2.png"
        }
    ]

    
    const [numStudents, setNumStudents] = useState(10);
    const [curStudentIdx, setCurStudentIdx] = useState(0);
    useEffect(() => {
        setNumStudents(10);
        setCurStudentIdx(0);
    }, [])

    const incStudent = () => 
    {
        setCurStudentIdx(prev => prev + 1);
    }

    useEffect(() => {
        if(curStudentIdx >= numStudents && numStudents != 0)
        {
            navigate("/");
        }
    }, [curStudentIdx, numStudents])


    return (
        <div className="outer">
            <div className="cardcontainer">
                <FlashCard incStudent={incStudent} numStudents={numStudents} curStudentIdx={curStudentIdx}></FlashCard>
            </div>
            <div className="progresscontainer">
                <div className="progressbar"> 
                    {curStudentIdx + 1} / {numStudents}
                </div>
            </div>
        </div>
    );
}