import {useEffect} from "react";
import {Home} from "./Home"
import {FCPage} from "./FCPage"
import {Dashboard} from "./Dashboard"
// import logo from './logo.svg';
import { BrowserRouter as Router, Routes, Route, Link, useLocation, useNavigate } from 'react-router-dom';
import {useParams} from 'react-router-dom';
import './App.css';



//these will have their own tsx files trust
// const Home = () => <h1>Home Page</h1>
// const FCPage = () => <h1>Flash Card Page</h1>
const ClassPage = () => <h1>Class Page</h1> //will need to make this dynamic at some point
// const Dashboard = () => <h1>Dashboard</h1>




//adding scrolling to the top whenever you go to a new page
const ScrollToTop = () => {
  const {pathname} = useLocation();
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

// const ClassEntry = () => {
//   return (
//     <div>

//     </div>
//   )
// }

// const ClassList = () => {
//   return (
//     <>

//     </div>
//   )
// }

const Navigation = () => {
  const navigate = useNavigate();
  // const toHome = () => {
  //   navigate("/");
  // }
  
  // const toFCPage = () => {
  //   navigate("/fcpage");
  // }

  // const toClassPage = () => {
  //   navigate("/classpage");
  // }

  // const toDashboard = () => {
  //   navigate("/dashboard");
  // }
  
  return(
  <nav className = "topbar">
    <a className = "site-title">LearnNames</a>
    <ul>
      <li><Link to="/">Home</Link></li>
      <li><Link to="dashboard">Dashboard</Link></li>
      <li><Link to="fcpage">Flash Cards</Link></li> 
    </ul>
  </nav>);
}


function App() {
  useEffect(() => {
    document.title = "Learn Names";
  }, []);


  return (
    <Router>
        <Navigation/>
        <div className="bottomSplits">
          {/* <div className="classDropdown">
            <a>Scrollbar here</a>
          </div> */}
          <div className="content">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/fcpage" element={<FCPage />} />
              <Route path="/classpage" element={<ClassPage />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="*" element={<h1>404 - Page not Found</h1>} />
            </Routes>
          </div>
          
        </div> 
        <div className="companyMsgs">Copyright Sauerkraut Learning 2024</div>
    </Router>
  );
}

export default App;
